import * as React from 'react';

import { Tasks, Column, Editing, Toolbar, Item, Validation, Gantt, ContextMenu, StripLine } from 'devextreme-react/gantt';

import 'devextreme/dist/css/dx.common.css';
import 'devexpress-gantt/dist/dx-gantt.min.css';

import 'devextreme-react/drop-down-button';
import styles from './GanttComponent.module.scss';

import { TaskUpdatedEvent, TaskDblClickEvent, SelectionChangedEvent, CustomCommandEvent, TaskUpdatingEvent, GanttScaleType } from 'devextreme/ui/gantt';

const enMessages = require('loc/DevExtreme-en.json');
const svMessages = require('loc/DevExtreme-sv.json');
import { locale, loadMessages } from 'devextreme/localization';
import { Toggle } from '@fluentui/react';

interface IGanttStrings {
  EditSelected: string;
  Export: string;
  Today: string;
  ShowCompleted: string;
}

interface IGanttTask {
  id: string;
  parentId: string;
  title: string;
  start: Date;
  end: Date;
  progress: number;
  color: string;
}

interface IGanttColumn {
  dataField: string;
  caption: string;
}

interface IGanttGroupOption {
  id: number;
  name: string;
}

loadMessages(enMessages);
loadMessages(svMessages);
let ganttLocale = 'en';
let currentThemeName = '';

const GanttComponent = (props: {
  today: Date;
  readOnly: boolean;
  columns: IGanttColumn[];

  groupBy: number;
  onGroupByChanged: (val: number) => void;

  showCompleted: boolean;
  setShowCompleted: (val: boolean) => void;

  groupByOptions: IGanttGroupOption[];
  tasks: IGanttTask[];
  onEditProperties: (guid: string) => void;
  onExport: (gantt) => void;
  onTaskUpdated: (guid: string, values: Partial<IGanttTask>) => void;
  onCancelTaskUpdating: (guid: string, values: Partial<IGanttTask>) => boolean | undefined;
  onRenderTooltip: (guid: string) => React.ReactNode;
  ganttStrings: IGanttStrings;
  locale: string;
  taskListWidth: number;
  autoUpdateParentTasks: boolean;
  shortUiLanguage: string;
  themeName: string;
}) => {

  const gantt = React.useRef<Gantt>();

  if (currentThemeName !== props.themeName) {
    switch (props.themeName) {
      case 'dark':
        require('devextreme/dist/css/dx.dark.css');
        break;
      case 'contrast':
        require('devextreme/dist/css/dx.contrast.css');
        break;
      default:
        require('devextreme/dist/css/dx.light.css');
        break;
    }
    require('./GanttComponentGlobal.module.scss');
    currentThemeName = props.themeName;
  }

  const currentLocale = props.shortUiLanguage;
  if (currentLocale !== ganttLocale) {
    locale(currentLocale);
    ganttLocale = currentLocale;
  }

  const resetView = () => {
    setTimeout(() => {
      gantt.current.instance.collapseAll();
      setTimeout(() => {
        gantt.current.instance.expandAllToLevel(1);
        setTimeout(() => {
          const today = props.today;
          const sixMonthAgo = new Date(new Date(today).setMonth(today.getMonth() - 6));
          gantt.current.instance.scrollToDate(sixMonthAgo);
        }, 0);
      }, 0);
    }, 0);
  };

  const onContentReady = () => {
    if (gantt.current) {
      resetView();
    }
  };

  React.useEffect(resetView, [props.groupBy]);

  const onEditProperties = (key: string) => {
    setTimeout(() => props.onEditProperties(key), 0);
  };

  const onTaskDblClick = (e: TaskDblClickEvent) => {
    e.cancel = true;
    onEditProperties(e.key);
  };

  const selectedKey = React.useRef(null);

  const onCustomCommandClick = (e: CustomCommandEvent) => {
    if (e.name === 'itemProperties') {
      onEditProperties(selectedKey.current);
    }
  };

  const contextMenuItems = [
    {
      icon: 'edit',
      name: 'itemProperties',
      text: props.ganttStrings.EditSelected,
    },
  ];

  const onSelectionChanged = (e: SelectionChangedEvent) => {
    selectedKey.current = e.selectedRowKey;
  };

  const onTaskUpdated = (e: TaskUpdatedEvent) => {
    props.onTaskUpdated(e.key, e.values);
  };

  const onTaskUpdating = (e: TaskUpdatingEvent) => {
    e.cancel = props.onCancelTaskUpdating(e.key, e.newValues);
  };

  const propertiesButtonOptions = {
    icon: 'edit',
    title: props.ganttStrings.EditSelected,
    stylingMode: 'text',
    onClick: () => onEditProperties(selectedKey.current),
  };

  const propertiesButtonExport = {
    icon: 'pdffile',
    title: props.ganttStrings.Export,
    stylingMode: 'text',
    onClick: () => props.onExport(gantt.current.instance),
  };

  const viewButtonOptions = {
    displayExpr: 'name',
    keyExpr: 'id',
    onSelectionChanged: (e) => {
      props.onGroupByChanged(e.item?.id);
    },
    items: props.groupByOptions,
    selectedItemKey: props.groupBy,
    stylingMode: 'text',
    useSelectMode: true,
    dropDownOptions: {
      minWidth: '18em'
    }
  };

  const [scaleType, setScaleType] = React.useState<any>('months');

  const optionChanged = React.useCallback((e) => {
    if (e.name === 'scaleType') {
      setScaleType(e.value);
    }
  }, []);

  return (
    <Gantt
      ref={gantt}
      height='100%'
      className='dx-theme-background-color' 
      taskListWidth={props.taskListWidth}
      headerFilter={{ visible: true, allowSearch: true }}
      filterRow={{ visible: true }}
      scaleType={scaleType}
      onCustomCommand={onCustomCommandClick}
      onTaskDblClick={onTaskDblClick}
      onSelectionChanged={onSelectionChanged}
      onTaskUpdated={onTaskUpdated}
      onTaskUpdating={onTaskUpdating}
      onContentReady={onContentReady}
      onOptionChanged={optionChanged}
      taskTooltipContentRender={(task) => {
        // #hack to disable progress for FOCUS item (hides progress triangle handle)
        const classList = gantt.current?.instance?.element()?.classList;
        if (classList) {
          if (typeof task.progress === 'undefined') {
            classList.add(styles.disableProgress);
          } else {
            classList.remove(styles.disableProgress);
          }
        }
        return props.onRenderTooltip(task);
      }}
    >

      <Tasks dataSource={props.tasks} />

      <StripLine title={props.ganttStrings.Today} start={props.today} cssClass={styles.currentDate} />

      <Toolbar>
        <Item name='collapseAll' />
        <Item name='expandAll' />
        <Item name='separator' />
        <Item name='zoomIn' />
        <Item name='zoomOut' />
        <Item name='separator' />
        <Item widget='dxButton' options={propertiesButtonOptions} />
        <Item name='separator' />
        <Item widget='dxDropDownButton' options={viewButtonOptions} />
        <Item name='separator' />
        <Item widget='dxButton' options={propertiesButtonExport} />
        <Item name='separator' />
        <Item render={() => <Toggle
          styles={{ root: { margin: '0 8px 0 8px' } }}
          inlineLabel
          label={props.ganttStrings.ShowCompleted}
          checked={props.showCompleted}
          onChange={(_, val) => props.setShowCompleted(!props.showCompleted)}
        />}
        />
      </Toolbar>

      <ContextMenu items={contextMenuItems} />

      {props.columns.map(c => <Column key={c.dataField} dataField={c.dataField} caption={c.caption} />)}

      <Validation autoUpdateParentTasks={props.autoUpdateParentTasks} />
      <Editing
        enabled={!props.readOnly}
        allowDependencyAdding={false}
        allowDependencyDeleting={false}
        allowResourceAdding={false}
        allowResourceDeleting={false}
        allowResourceUpdating={false}
        allowTaskAdding={false}
        allowTaskDeleting={false}
        allowTaskResourceUpdating={false}
        allowTaskUpdating={true}
      />
    </Gantt>
  );
};

export default GanttComponent;
